import { assertExhaustive } from './asserts';

export enum MediaType {
  Image = 100,
  Video,
  Audio,
}

export enum MediaFormatVersion {
  Raw = 'raw',
  FHD = 'fhd',
  HD = 'hd',
  MD = 'md',
  SM = 'sm',
  HQ = 'hq',
  SQ = 'sq',
}

export enum MediaTranscodeStatus {
  Pending = 0,
  Processing,
  Failed,
  Ready,
}

export interface MediaFormat {
  version: MediaFormatVersion;
  width: number;
  height: number;
  size: number;
  url: string;
  length: number;
  silent: boolean;
}

type ISO8601String = string;

export interface Media {
  id: string;
  type: MediaType;
  url: string;
  hash: string;
  uid: string;
  transcodeStatus: MediaTranscodeStatus;
  scene: string | null;
  firstThumbnailUrl?: string;
  lastThumbnailUrl?: string;
  formats: MediaFormat[];
  createdAt: ISO8601String;
  updatedAt: ISO8601String;
}

export enum VolumeLevel {
  Full = 'full',
  MidHigh = 'mid-high',
  MidLow = 'mid-low',
  Background = 'background',
}

export class VolumeLevelUtils {
  static ConvertToScale(level: VolumeLevel | undefined | null): number {
    switch (level) {
      case undefined:
      case null:
        return 1;
      case VolumeLevel.Full:
        return 1;
      case VolumeLevel.MidHigh:
        return 0.75;
      case VolumeLevel.MidLow:
        return 0.5;
      case VolumeLevel.Background:
        return 0.25;
      default:
        assertExhaustive(level);
        return 1;
    }
  }
}

export interface MediaData {
  id: string;
  volumeLevel?: VolumeLevel;
  loop?: boolean;
}
